export const routes = [
    {
        path: '/sales',
        name: 'sales.browse',
        component: () => import(/* webpackChunkName: "SalesBrowse" */ '@/views/app/SalesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales/:id/clone',
        name: 'sales.clone',
        component: () => import(/* webpackChunkName: "SalesActions" */ '@/views/app/SalesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales/create',
        name: 'sales.create',
        component: () => import(/* webpackChunkName: "SalesActions" */ '@/views/app/SalesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales/:id/edit',
        name: 'sales.edit',
        component: () => import(/* webpackChunkName: "SalesActions" */ '@/views/app/SalesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sales/:id/delete',
        name: 'sales.delete',
        component: () => import(/* webpackChunkName: "SalesActions" */ '@/views/app/SalesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/sales/:id/show',
        name: 'sales.show',
        component: () => import(/* webpackChunkName: "SalesActions" */ '@/views/app/SalesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]